/**
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Material Kit 2 React React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Navbar.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `name` key is used for the name of the route on the Navbar.
  2. The `icon` key is used for the icon of the route on the Navbar.
  3. The `collapse` key is used for making a collapsible item on the Navbar that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  4. The `route` key is used to store the route location which is used for the react router.
  5. The `href` key is used to store the external links location.
  6. The `component` key is used to store the component of its route.
  7. The `dropdown` key is used to define that the item should open a dropdown for its collapse items .
  8. The `description` key is used to define the description of
          a route under its name.
  9. The `columns` key is used to define that how the content should look inside the dropdown menu as columns,
          you can set the columns amount based on this key.
  10. The `rowsPerColumn` key is used to define that how many rows should be in a column.
*/

// @mui icons
import CleaningServicesIcon from "@mui/icons-material/CleaningServices";
import WindowIcon from "@mui/icons-material/Window";
import IronIcon from "@mui/icons-material/Iron";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import RecordVoiceOverIcon from "@mui/icons-material/RecordVoiceOver";
import PhoneIcon from "@mui/icons-material/Phone";
import MailIcon from "@mui/icons-material/Mail";
import MapsHomeWorkIcon from "@mui/icons-material/MapsHomeWork";

const routes = [
  {
    name: "Services",
    icon: <MapsHomeWorkIcon />,
    collapse: [
      {
        name: "Aide ménagère",
        description: "Tout pour l'entretien de votre maison",
        icon: <CleaningServicesIcon />,
        href: "#menage",
        inplace: true,
      },
      {
        name: "Nettoyage vitres et baies vitrées",
        description: "Polissage, lissage, sage et tous les trucs en age",
        icon: <WindowIcon />,
        href: "#window",
        inplace: true,
      },
      {
        name: "Repassage",
        description: "Des fringues propres ca tue",
        icon: <IronIcon />,
        href: "#repassage",
        inplace: true,
      },
    ],
  },
  {
    name: "Avis",
    icon: <RecordVoiceOverIcon />,
    href: "#avis",
    inplace: true,
  },
  {
    name: "Facebook",
    icon: <FacebookIcon />,
    href: "https://www.facebook.com/Nettoyage.domicile.entretien/",
  },
  {
    name: "Instagram",
    icon: <InstagramIcon />,
    href: "https://instagram.com/servicesjns?utm_medium=copy_link",
  },
  {
    name: "06 59 64 80 02",
    icon: <PhoneIcon />,
    href: "tel:0659648002",
  },
  {
    name: "JNS",
    icon: <MailIcon />,
    href: "mailto:jerome.nieto5@gmail.com",
  },
];

export default routes;
