/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { useState, useEffect } from "react";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKBadge from "components/MKBadge";
import MKTypography from "components/MKTypography";
import Carousel from "react-material-ui-carousel";
// Presentation page components
import breakpoints from "assets/theme/base/breakpoints";
// Data
import data from "pages/Presentation/sections/data/designBlocksData";
import saut from "assets/images/saut.png";

function DesignBlocks() {
  const [mobileView, setMobileView] = useState(false);
  useEffect(() => {
    // A function that sets the display state for the DefaultNavbarMobile.
    function displayMobileNavbar() {
      if (window.innerWidth < breakpoints.values.lg) {
        setMobileView(true);
      } else {
        setMobileView(false);
      }
    }
    window.addEventListener("resize", displayMobileNavbar);
    displayMobileNavbar();
  }, []);

  const renderData = data.map(({ title, logo, description, items, id }) => (
    <Grid container spacing={3} sx={{ mb: 0 }} key={title} id={id}>
      <Grid
        item
        xs={12}
        lg={3}
        sx={{
          backgroundImage: logo ? `url(${logo})` : "",
          backgroundSize: "contain",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          placeItems: "center",
        }}
      >
        <MKBox position="sticky" top="100px" pb={{ xs: 2, lg: 6 }}>
          <MKTypography variant="h3" fontWeight="bold" mb={1}>
            {title}
          </MKTypography>
          <MKTypography variant="body2" fontWeight="regular" color="secondary" mb={1} pr={2}>
            {description}
          </MKTypography>
        </MKBox>
      </Grid>
      <Grid item xs={12} lg={9}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12} sx={{ mb: 12 }}>
            {items.map((item) => (
              <Carousel
                key={id + item.id}
                sx={{ minHeight: mobileView ? (window.innerWidth - 112) * (720 / 960) : 700 }}
              >
                {item.images.map((image) => (
                  <div key={id + image.id}>
                    <img src={image.image} style={{ width: "100%" }} alt={image.description} />
                  </div>
                ))}
              </Carousel>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  ));

  return (
    <MKBox component="section" my={6} py={6}>
      <Container>
        <Grid
          container
          item
          xs={12}
          lg={6}
          flexDirection="column"
          alignItems="center"
          sx={{
            textAlign: "center",
            my: 6,
            mx: "auto",
            px: 0.75,
            backgroundImage: `url(${saut})`,
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "400px 1px",
          }}
        >
          <MKBadge
            variant="contained"
            color="info"
            badgeContent="Nos services"
            container
            sx={{ mb: 2 }}
          />
          <MKTypography variant="h2" fontWeight="bold">
            Tous ce que nous vous proposons
          </MKTypography>
          <MKTypography variant="body1" color="text">
            Nous offrons de multiple prestations
          </MKTypography>
        </Grid>
      </Container>
      <Container sx={{ mt: 6 }}>{renderData}</Container>
    </MKBox>
  );
}

export default DesignBlocks;
