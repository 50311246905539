// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";

// Material Kit 2 React components
import MKTypography from "components/MKTypography";

// Images
import logoCT from "assets/images/logo.png";

const date = new Date().getFullYear();

export default {
  brand: {
    name: "JNS Services",
    image: logoCT,
    route: "/",
  },
  socials: [
    {
      icon: <FacebookIcon />,
      link: "https://www.facebook.com/Nettoyage.domicile.entretien/",
    },
    {
      icon: <InstagramIcon />,
      link: "https://www.instagram.com/servicesjns/?utm_medium=copy_link",
    },
  ],
  menus: [
    {
      name: "L'entreprise",
      items: [
        {
          name: "A propos de nous",
          href: "#aboutus",
          inplace: true,
        },
      ],
    },
    {
      name: "Services",
      items: [
        { name: "ménage", href: "#menage", inplace: true },
        { name: "Vitres et baies vitrées", href: "#window", inplace: true },
        { name: "Repassage", href: "#repassage", inplace: true },
      ],
    },
    {
      name: "Les avis",
      items: [
        {
          name: "Ce qu'ils pensent de nous",
          href: "#avis",
          inplace: true,
        },
      ],
    },
  ],
  copyright: (
    <MKTypography variant="button" fontWeight="regular">
      Tous droits réservés. Copyright &copy; {date} JNS.
    </MKTypography>
  ),
};
