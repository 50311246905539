/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import menageLogo from "assets/images/aspi.png";
import vitre from "assets/images/vitre.png";
import repassage from "assets/images/repassage.png";

export default [
  {
    title: "Ménage",
    description: "Quelque soit votre surface",
    id: "menage",
    logo: menageLogo,
    items: [
      {
        images: [
          {
            id: 1,
            description: "Pub #2",
            image: `${process.env.PUBLIC_URL}/photos/CREATION2.png`,
          },
          {
            id: 2,
            description: "Salle de bain #1",
            image: `${process.env.PUBLIC_URL}/photos/particulier_chmb.jpg`,
          },
          {
            id: 3,
            description: "Salle a manger #2",
            image: `${process.env.PUBLIC_URL}/photos/particulier_chmb2.jpg`,
          },
          {
            id: 4,
            description: "Salle a manger #3",
            image: `${process.env.PUBLIC_URL}/photos/particulier_couloir.jpg`,
          },
          {
            id: 5,
            description: "Salle a manger #4",
            image: `${process.env.PUBLIC_URL}/photos/particulier_cui2.jpg`,
          },
          {
            id: 11,
            description: "Salle a manger #5",
            image: `${process.env.PUBLIC_URL}/photos/particulier_lgm.jpg`,
          },
          {
            id: 6,
            description: "Salle a manger #6",
            image: `${process.env.PUBLIC_URL}/photos/particulier_sam.jpg`,
          },
          {
            id: 7,
            description: "Salle a manger #7",
            image: `${process.env.PUBLIC_URL}/photos/particulier_sam4.jpg`,
          },
          {
            id: 8,
            description: "Salle a manger #8",
            image: `${process.env.PUBLIC_URL}/photos/particulier_sam5.jpg`,
          },
          {
            id: 9,
            description: "Salle a manger #9",
            image: `${process.env.PUBLIC_URL}/photos/particulier_sam6.jpg`,
          },
          {
            id: 10,
            description: "Salle a manger #10",
            image: `${process.env.PUBLIC_URL}/photos/particulier_sam7.jpg`,
          },
        ],
        description: "Particulier",
      },
    ],
  },
  {
    title: "Vitres et baies vitrées",
    description: "Notre spécialité",
    id: "window",
    logo: vitre,
    items: [
      {
        images: [
          {
            id: 1,
            description: "Pub #1",
            image: `${process.env.PUBLIC_URL}/photos/CREATION1.png`,
          },
          {
            id: 3,
            description: "Vitre #3",
            image: `${process.env.PUBLIC_URL}/photos/LGMVITRES.jpg`,
          },
          {
            id: 4,
            description: "Vitre #4",
            image: `${process.env.PUBLIC_URL}/photos/LGMVITRES2.jpg`,
          },
          {
            id: 5,
            description: "Vitre #5",
            image: `${process.env.PUBLIC_URL}/photos/LGMVITRES3.jpg`,
          },
          {
            id: 6,
            description: "Vitre #6",
            image: `${process.env.PUBLIC_URL}/photos/LGMVITRES4.jpg`,
          },
          {
            id: 7,
            description: "Vitre #7",
            image: `${process.env.PUBLIC_URL}/photos/VITRESCAMARGUES.jpg`,
          },
          {
            id: 2,
            description: "Pub #2",
            image: `${process.env.PUBLIC_URL}/photos/CREATION3.jpg`,
          },
          {
            id: 8,
            description: "Vitre #8",
            image: `${process.env.PUBLIC_URL}/photos/VITRES6.jpg`,
          },
          {
            id: 9,
            description: "Vitre #9",
            image: `${process.env.PUBLIC_URL}/photos/VITRES7.jpg`,
          },
          {
            id: 10,
            description: "Vitre #10",
            image: `${process.env.PUBLIC_URL}/photos/VITRES8.jpg`,
          },
          {
            id: 11,
            description: "Vitre #11",
            image: `${process.env.PUBLIC_URL}/photos/VITRES9.jpg`,
          },
          {
            id: 12,
            description: "Vitre #12",
            image: `${process.env.PUBLIC_URL}/photos/VITRES10.jpg`,
          },
          {
            id: 13,
            description: "Vitre #13",
            image: `${process.env.PUBLIC_URL}/photos/VITRES11.jpg`,
          },
          {
            id: 14,
            description: "Vitre #14",
            image: `${process.env.PUBLIC_URL}/photos/VITRES12.jpg`,
          },
        ],
        description: "Particulier",
      },
    ],
  },
  {
    title: "Repassage",
    description: "Notre spécialité",
    id: "repassage",
    logo: repassage,
    items: [
      {
        images: [
          {
            id: 1,
            description: "Pub #1",
            image: `${process.env.PUBLIC_URL}/photos/pub3.png`,
          },
        ],
        description: "Particulier",
      },
    ],
  },
];
