/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKAvatar from "components/MKAvatar";
import MKTypography from "components/MKTypography";

// Images
import profilePicture from "assets/images/logo2.png";

function Profile() {
  return (
    <MKBox component="section" id="aboutus">
      <Container>
        <Grid container item xs={12} justifyContent="center" mx="auto">
          <MKBox mt={{ xs: -16, md: -20 }} textAlign="center">
            <MKAvatar src={profilePicture} alt="Burce Mars" size="xxl" shadow="xl" />
          </MKBox>
          <Grid container justifyContent="center" py={6}>
            <Grid item xs={12}>
              <MKBox display="flex" justifyContent="space-between" alignItems="center" mb={1}>
                <MKTypography variant="h3">JNS Services</MKTypography>
              </MKBox>
              <Grid container spacing={3} mb={3}>
                <Grid item>
                  <MKTypography component="span" variant="body2" fontWeight="bold">
                    323&nbsp;
                  </MKTypography>
                  <MKTypography component="span" variant="body2" color="text">
                    Posts
                  </MKTypography>
                </Grid>
                <Grid item>
                  <MKTypography component="span" variant="body2" fontWeight="bold">
                    3.5k&nbsp;
                  </MKTypography>
                  <MKTypography component="span" variant="body2" color="text">
                    Followers
                  </MKTypography>
                </Grid>
                <Grid item>
                  <MKTypography component="span" variant="body2" fontWeight="bold">
                    260&nbsp;
                  </MKTypography>
                  <MKTypography component="span" variant="body2" color="text">
                    Following
                  </MKTypography>
                </Grid>
              </Grid>
              <MKTypography variant="body1" fontWeight="light" color="text">
                EN 2017, JNS SERVICES est né en Camargue et plus précisément dans le secteur de la
                Grande Motte et du Grau du Roi en plaçant le service &agrave; domicile au coeur de
                ses préoccupations. Progressivement et grâce &agrave; de nombreux efforts de
                développement et de communication, la JNS Services s&apos;est étendu au-del&agrave;
                des frontières du pays camarguais. L&apos;idée de la création d&apos;une compagnie
                de services n&apos;est pourtant pas chose nouvelle. Dès l&apos;âge de 14 ans son
                créateur évoquait ardemment le souhait de mêler Service et aventure de
                l&apos;entreprenariat. En mars 2020, l&apos;autoentreprise développée depuis trois
                ans a laissé place &agrave; la SARL JNS SERVICES en embauchant sa première employée.
                Depuis, elle n&apos;a de cesse de s&apos;accroître, compte maintenant trois
                employées et multiplie les projets en dépit d&apos;une crise sanitaire aux lourdes
                conséquences. Confiant en l&apos;avenir et soucieux du travail bien fait, c&apos;est
                auprès des clients actuels et futurs que JNS compte bien remplir les futures pages
                blanches de son histoire... <br />
              </MKTypography>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Profile;
