/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
import HorizontalTeamCard from "examples/Cards/TeamCards/HorizontalTeamCard";

// Images
import team1 from "assets/images/woman1.png";
import team2 from "assets/images/businessman.png";
import team3 from "assets/images/woman.png";
import team4 from "assets/images/avatar.png";

function Team() {
  return (
    <MKBox
      component="section"
      variant="gradient"
      bgColor="dark"
      position="relative"
      py={6}
      px={{ xs: 2, lg: 0 }}
      mx={-2}
      id="avis"
      sx={{
        background: "linear-gradient(195deg, #1c8df5, #0e5402)",
      }}
    >
      <Container>
        <Grid container>
          <Grid item xs={12} md={8} sx={{ mb: 6 }}>
            <MKTypography variant="h3" color="white">
              Ils nous ont fait confiance
            </MKTypography>
            <MKTypography variant="body2" color="white" opacity={0.8}>
              Voici un échantillon des retours clients
            </MKTypography>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={6}>
            <MKBox mb={1}>
              <HorizontalTeamCard
                image={team1}
                name="Sophie P"
                position={{ color: "info", label: "Client JNS via Google" }}
                description="Supers services. Je les ai appellé pour nettoyer de fond en comble mon appartement laissé bien sale par un locataire ..."
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={6}>
            <MKBox mb={1}>
              <HorizontalTeamCard
                image={team2}
                name="Bast Fx"
                position={{ color: "info", label: "Client JNS via Google" }}
                description="Ravis des services de cet entreprise ! Professionnel, je recommande !"
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={6}>
            <MKBox mb={{ xs: 1, lg: 0 }}>
              <HorizontalTeamCard
                image={team3}
                name="Déborah T"
                position={{ color: "info", label: "Client JNS via Google" }}
                description="Relationnel et service au top,je vous le recommande !"
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={6}>
            <MKBox mb={{ xs: 1, lg: 0 }}>
              <HorizontalTeamCard
                image={team4}
                name="Andrea B"
                position={{ color: "info", label: "Client JNS via Google" }}
                description="Cela fait un an que je solicite leurs services, j'en suis toujours aussi satisfaite. Je suis maniaque pourtant mais je ne regrette pas mon choix... Service impeccable en plus d'une équipe réactive et formidable. Merci"
              />
            </MKBox>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Team;
